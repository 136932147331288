import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Helhetlig`}</h2>
    <p>{`Designsystemets mål er å tilby helhetlige løsninger og gode, konsistente brukeropplevelser som henger sammen med hverandre. Kjente og gjennomgående problemstillinger skal løses uten at du som bruker trenger å finne opp hjulet på nytt.
Dette skal vi gjøre ved å lage gjenbrukbare komponenter som kan brukes i ulike kontekster, og i forskjellige rammeverk og plattformer. Alle som vil skal kunne bruke designsystemet, uavhengig av hvilken teknologistack de har valgt fra før.`}</p>
    <p>{`Vi tror det blir lettere å samarbeide på tvers av fagområdene hvis vi forholder oss til de samme begrepene og verktøyene. Derfor er det viktig å holde skissene i Figma, implementasjonen på GitHub og dokumentasjonen på design.sparebank1.no i synk med hverandre.`}</p>
    <h2>{`Levende`}</h2>
    <p>{`Designsystemet skal være levende. Med det mener vi at designsystemet skal henge med i tiden og legge til rette for videreutvikling og jevnlige oppdateringer. Systemet skal være tilpasningsdyktig og holde høy kvalitet.`}</p>
    <p>{`Vi tenker bærekraftig og langsiktig når vi utvikler nye eller forbedrer eksisterende komponenter.`}</p>
    <h2>{`Åpen`}</h2>
    <p>{`Det skal være enkelt for alle å ta eierskap til designsystemet og involvere seg med bidrag og innspill. Kildekoden er derfor åpen og tilgjengelig for alle på Github. `}</p>
    <h2>{`Profil`}</h2>
    <p>{`Ved å bruke designsystemet skal du kunne være trygg på at du følger SpareBank 1 sin merkevare og visuelle profil, og du får eventuelle endringer i designet «gratis» ved å oppdatere komponentene til seneste versjon.`}</p>
    <h2>{`Kvalitet`}</h2>
    <p>{`Alle komponentene skal oppfylle kravene til universell utforming. Vi jobber også for at de skal ivareta behov og retningslinjer som går utover bare lovkravene. Som bruker skal du kunne være trygg på at komponentene er universelt utformet og fungerer overalt.`}</p>
    <p>{`Universell utforming, vedlikehold og forskjellige teknologi-stacker er noen av problemstillingene vi tar stilling til under utvikling av nye og eksisterende komponenter. Resultatet skal være et robust designsystem av høy kvalitet, som vil være godt rustet for å henge med på endringer i teknologi, tilgjengelighetskrav og organisasjonen vår.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      